@import url(https://fonts.googleapis.com/css?family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Teko|Noto+Sans|Source+Sans+Pro:300,400,600);
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  padding-top: 10px;
}

body {
  background-image: url(/static/media/Moon_Santa_STA.793e5ebc.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.main-page {
  height: 80vh;
  overflow-y: auto; /* Added to help header stay fixed */
  -ms-overflow-style: none;  /* IE and Edge */ /* Added to help header stay fixed */
  scrollbar-width: none;  /* Firefox */ /* Added to help header stay fixed */
}

.main-page::-webkit-scrollbar { /* Added to help header stay fixed */
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.component-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 80vw;
  height: auto;
}
.component-footer-col {
  height: 20vh;
}

.App-main {
  -webkit-filter: blur(10px);
          filter: blur(10px);
  pointer-events: none;
}

.hiddenPete {
  visibility: hidden;
  position: absolute;
  height: 255px;
  z-index: -1;
  bottom: 0px;
  right: 0px;
}

.calendar-windows-container {
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  height: 90vh;
}
.calendar-windows-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.calendar-window {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20vh;
  width: 15vh;
  padding: 0;
  color: #ffffff;
}

.calendar-window img {
  height: 15vh;
  width: 15vh;
  padding-bottom: 10px;
}

.calendar-window b {
  font-size: 2rem;
  color: white;
  height: 3rem;
  width: 3rem;
  background-color: rgba(60, 60, 59, 0.75);
  border-radius: 50%;
}

.activity-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 30px;
}

.activity-row {
  background-color: rgba(60, 60, 59, 0.75);
  padding: 10px 0;
  margin: 0;
}

.activity-row {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;;
}

Button.btn.btn-primary {
  margin: 10px auto;
  background-color: rgba(60, 60, 59, 0.75);
  border-color: white;
}

.wall-of-awesome-image {
  height: 55vh;
  width: 100%;
}

.challenge-text {
  padding-top: 20px;
  background-color: #f2f2f2;
  margin: 0;
  color: #3C3C3B;
}

.challenge-text p {
  padding-top: 20px;
  color: #3C3C3B;
}

.challenge-img {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1024px) {
  .calendar-window {
    height: 17vh;
    width: 12vh;
    margin: 0;
  }
  .calendar-window img {
    height: 10vh;
    width: 10vh;
  }
}

@media screen and (max-width: 770px) {
  .calendar-window {
    height: 14vh;
    width: 11vh;
  }
  .calendar-window img {
    height: 11vh;
    width: 11vh;
  }
  .App header h1 {
    font-size: 1.5rem;
  }
  .App header h2 {
    font-size: 1.25rem;
  }
  .awesome,
  .activity-row {
    border-radius: 0px;
  }
  .component-header-col {
    margin-bottom: 0;
  }
  .component-footer-col {
    margin-top: 0;
  }
}

@media screen and (max-width: 500px) {
  .activity-page-container h3,
  .activity-page-container h4 {
    font-size: 0.75rem;
  }
  .calendar-window b {
    font-size: 0.5rem !important;
    line-height: 1.5rem !important;
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  Button.btn.btn-primary {
    font-size: 0.75rem !important;
    font-weight: bold;
  }
  .calendar-windows-container {
    margin-top: 5vh;
  }
  .activity-row {
    height: auto;
    overflow-y: scroll;
  }
  .component-col {
    height: auto;
  }
}
@media screen and (max-width: 375px) {
  Button.btn.btn-primary {
    font-weight: bold;
    padding: 2.5px;
    display: block;
    font-size: 0.5rem !important;
  }
  .calendar-window {
    height: 15vh;
  }
  .calendar-window b {
    font-size: 0.5rem !important;
    line-height: 1.25rem !important;
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
}

.App header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 10vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
}

header .header-col {
  display: flex;
  flex-direction: row;
}

.App header .header-col:nth-child(1),
.App header .header-col:nth-child(3) {
  width: 30vw;
}
.App header .header-col:nth-child(2) {
  width: 40vw;
  justify-content: center;
}

.App header .title {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.App header h1 {
  font-size: 2rem;
  color: #ffffff;
  font-weight: bold;
}

.App header h2 {
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 0;
}

.App header h1,
.App header h2 {
  color: #f2f2f2;
  font-weight: bold;
}

#logo {
  border-radius: 90px;
  background-color: #fff;
  height: 85px;
  margin-right: 10px;
  margin-left: 10px;
}

.header-col Button.btn.btn-primary {
  margin: auto 0;
}

@media screen and (max-width: 1418px) {
  .App header .header-col:nth-child(1),
  .App header .header-col:nth-child(3) {
    width: 25vw;
  }
  .App header .header-col:nth-child(2) {
    width: 50vw;
  }
}

@media screen and (max-width: 1200px) {
  .App header .header-col:nth-child(1),
  .App header .header-col:nth-child(3) {
    width: 20vw;
  }
  .App header .header-col:nth-child(2) {
    width: 60vw;
  }
  .header-col Button.btn.btn-primary {
    font-size: 0.75rem !important;
    font-weight: bold;
    height: auto;
    width: 100px;
  }
}

@media screen and (max-width: 991px) {
  .App header .header-col:nth-child(1),
  .App header .header-col:nth-child(3) {
    width: 25vw;
  }
  .App header .header-col:nth-child(2) {
    width: 50vw;
  }
  #logo {
    height: 4.25rem !important;
  }
  .App header h1 {
    font-size: 1.25rem !important;
  }
  .App header h2 {
    font-size: 1rem  !important;
  }
}

@media screen and (max-width: 855px) {
  .App header .header-col:nth-child(1),
  .App header .header-col:nth-child(3) {
    width: 25vw;
  }
  .App header .header-col:nth-child(2) {
    width: 50vw;
  }
}

@media screen and (max-width: 770px) {
  #logo {
    height: 3.75rem !important;
  }
  .App header h1 {
    font-size: 0.75rem;
  }
  .App header h2 {
    font-size: 0.5rem;
  }
}

@media screen and (max-width: 747px) {
  .App header .header-col:nth-child(1),
  .App header .header-col:nth-child(3) {
    width: 20vw;
  }
  .App header .header-col:nth-child(2) {
    width: 60vw;
  }
  .header-col Button.btn.btn-primary {
    width: 100px;
  }
}

@media screen and (max-width: 620px) {
  #logo {
    height: 3rem !important;
  }
  .App header h1 {
    font-size: 0.25rem;
  }
  .App header h2 {
    font-size: 0.25rem;
  }
  .header-col Button.btn.btn-primary {
    margin: 10px auto 0;
    width: auto;
  }
  .App header {
    flex-direction: column;
    height: 16vh;
  }
  .App header .header-col:nth-child(1) {
    width: 0vw;
  }
  .App header .header-col:nth-child(2) {
    width: 100vw;
  }
  .App header .header-col:nth-child(3) {
    width: 100vw;
  }
}

@media screen and (max-width: 500px) {
  .App header {
    height: 20vh;
  }
  #logo {
    height: 4rem !important;
  }
  .App header h1 {
    font-size: 1rem !important;
  }
  .App header h2 {
    font-size: 0.75rem  !important;
  }
}

@media screen and (max-width: 320px) {
  #logo {
    height: 3rem;
  }
  .App header h1 {
    font-size: 0.75rem !important;
  }
  .App header h2 {
    font-size: 0.5rem  !important;
  }
}

.loading-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
}

@keyframes loadingTextFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.video-player {
  margin: 20px auto;
  opacity: 0;
  width: 100% !important;
}

.video-instructions {
  text-align: left;
  width: 100%;
  padding: 40px;
  border: 1px solid White;
  margin: 10px 0 20px;
}

@keyframes videoFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 685px) {
  .video-instructions {
    font-size: 0.75rem;
    width: 100%;
    padding: 5px;
  }
}

.woa-carousel h1 {
  font-size: 1.5rem;
}
.woa-carousel h2 {
  font-size: 1.25rem;
}
.woa-carousel h3 {
  font-size: 1rem;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  height: 3rem;
  width: 3rem;
}

.woa-text {
  border: 1px solid White;
  padding: 5px;
  margin: 5px auto 15px;
  width: 100%;
}

.wall-of-awesome-image {
  height: 60vh;
  width: 100%;
}

.awesome-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.awesome {
  background-color: rgba(60, 60, 59, 0.75);
  padding: 10px 0;
  margin: 2rem 0 0;
  width: 90%;
  height: auto;
}

.carousel-image {
  width: 100% !important;
}

@media screen and (max-width: 1024px) {
  .woa-text {
    width: auto;
  }
  .woa-carousel h1 {
    font-size: 1rem;
  }
  .woa-carousel h2 {
    font-size: 0.75rem;
  }
  .woa-carousel h3 {
    font-size: 0.5rem;
  }
  .wall-of-awesome-image {
    height: 50vh;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 2rem;
    width: 2rem;
  }
}

@media screen and (max-width: 800px) {
  .wall-of-awesome-image {
    height: 40vh;
  }
}

@media screen and (max-width: 700px) {
  .wall-of-awesome-image {
    height: 30vh;
  }
}

@media screen and (max-width: 600px) {
  .woa-text {
    margin: 0 auto 10px;
  }
  .wall-of-awesome-image {
    height: 20vh;
  }
  .woa-carousel h1 {
    font-size: 0.75rem;
  }
  .woa-carousel h2 {
    font-size: 0.5rem;
  }
  .woa-carousel h3 {
    font-size: 0.25rem;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 1rem;
    width: 1rem;
    margin-top: 5rem;
  }
  .carousel-indicators {
    margin-bottom: 0;
  }
}

.crossword-container {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 0;
}

.clue {
  font-size: 12px;
}

.direction .header {
  font-size: 14px;
  font-weight: bold;
}

div.clues {
  height: 50vh;
  overflow-y: scroll;
}

.jigsaw-container {
  max-width: 45vw;
}

.jigsaw {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

.jigsaw__solved-board:before {
  content: '';
  background-color: rgba(0, 102, 102, 0.6);
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.jigsaw__solved-board,
.jigsaw__shuffled-board {
  position: relative;
  margin: 20px auto;
  width: 371px;
  height: 371px;
  list-style-type: none;
  padding: 0;
  font-size: 0;
  background-size: 371px 371px;
  background-repeat: no-repeat;
}

.jigsaw li {
  position: relative;
  text-align: left;
  display: inline-block;
  width: 53px;
  height: 53px;
  list-style-type: none;
  border: 1px solid #ddd;
  border-width: 1px 1px 1px 1px;
}

.jigsaw li:empty:hover:before {
  opacity: 1;
}

.jigsaw li img {
  width: 51px;
  height: 51px;
  position: absolute;
  cursor: -webkit-grab;
  cursor: grab;
  transition: transform 200ms ease, box-shadow 200ms ease;
}

.jigsaw li img:hover {
  z-index: 2;
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 1024px) {
  .jigsaw-container {
    max-width: 100vw;
  }
  .jigsaw {
    flex-direction: column;
  }
}

@media screen and (max-width: 530px) {
  .jigsaw li {
    width: 5vh;
    height: 5vh;
  }

  .jigsaw li img {
    width: 5vh;
    height: 5vh;
  }

  .jigsaw__solved-board,
  .jigsaw__shuffled-board {
    width: 35vh;
    height: 35vh;
    background-size: 35vh 35vh;
  }
}

.joke-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 0;
}

.joke-container img {
  width: 80%;
  border-radius: 20px;
}

@media screen and (max-width: 760px) {
  .joke-container img {
    width: 100%;
    border-radius: 0px;
  }
}
.challenge-instructions {
  text-align: center;
  width: 80%;
  font-size: 1rem;
  margin: auto;
}

@media screen and (max-width: 620px) {
  .challenge-instructions {
    font-size: 0.5rem;
    width: 100%;
    padding: 5px;
  }
}

.vr-instructions {
  text-align: left;
  font-size: 1rem;
  width: 100%;
  padding: 40px;
  border: 1px solid White;
  margin: 10px 0 20px;
}
.submit-vr {
  margin-bottom: 10px;
}
.submit-vr-message {
  font-size: 1rem;
  margin: 0 auto 10px;
}

@media screen and (max-width: 770px) {
  .vr-instructions {
    font-size: 0.75rem;
    padding: 10px;
    margin: 10px 0 0;
  }
  .submit-vr {
    height: 1.75rem;
    font-size: 0.75rem;
  }
  .submit-vr-message {
    font-size: 0.5rem;
  }
}
@media screen and (max-width: 320px) {
  .vr-instructions {
    font-size: 0.5rem;
    padding: 5px;
    margin: 10px 0 5px;
  }
  .submit-vr {
    height: 1.5rem;
    font-size: 0.5rem;
  }
}

/**
 * Import Google Fonts.
 */

 /**
  * Custom Properties for keeping the CSS 'DRY'!
  */
 .sudoku-container {
   max-width: 60vw; /** Orriginal CSS */
   --background-color: #fff;
   --color-blue: hsl(210, 88%, 56%);
   --color-grey: hsl(213, 30%, 29%);
   --color-grey-light: hsl(213, 30%, 59%);
   --color-grey-lighter: hsl(213, 30%, 79%);
   --color-orange: hsl(34, 26%, 89%);
   --color-orange-dark: hsl(34, 76%, 89%);
 }
 
 /**
  * CSS Resets for all elements.
  */
 * {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
 }
 
 /**
  * Declare default values here.
  */
 .sudoku-container {
   background: var(--background-color);
   color: var(--color-blue);
   font-family: 'Source Sans Pro', sans-serif;
   line-height: 1.4em;
   font-weight: 300;
 }
 
 /**
  * A container for the game.
  */
 .sdcontainer {
   max-width: 700px;
   min-width: 220px;
   margin: 0 auto;
   padding: 0 10px;
 }
 
 /**
  * Header Section
  */
 .sdheader {
   position: relative;
   /* border-bottom: 2px solid var(--color-grey); */
   padding: 0 20px;
 }
 
 .sdheader h1 {
   float: left;
   font-size: 24px;
   font-weight: 600;
   line-height: 2.75;
   color: var(--color-grey);
   -webkit-text-rendering: optimizeLegibility;
   -moz-text-rendering: optimizeLegibility;
   text-rendering: optimizeLegibility;
 }
 
 .sdheader__group-one {
   color: var(--color-blue);
 }
 
 .sdheader__group-two {
   color: var(--color-grey-light);
 }
 
 .sdheader h2 {
   float: right;
   font-size: 18px;
   font-weight: 400;
   line-height: 2;
   color: var(--color-grey);
   padding-top: 16px;
   cursor: pointer;
 }
 
 .sdheader h2:hover {
   text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
 }
 
 .sdheader::after {
   content: "";
   display: block;
   clear: both;
 }
 
 /**
  * Inner Container - contains both Game Section & Status Section.
  */
 .innercontainer {
   display: flex;
   color: var(--color-grey);
   flex-wrap: wrap;
   padding: 0 20px;
 }
 
 /**
  * Game Section
  */
 .game {
   width: 70%;
 }
 
 .game__board {
   font-family: 'Noto Sans', sans-serif;
   font-size: 26px;
   margin: 30px 0;
   border: 2px solid var(--color-grey);
   border-collapse: collapse;
 }
 
 .game__row:nth-child(3n) {
   border-bottom: 2px solid var(--color-grey);
 }
 
 .game__cell {
   border: 1px solid var(--color-grey-lighter);
   padding: 12px 16px;
   color: var(--background-color);
   cursor: pointer;
 }
 
 .game__cell.game__cell--filled {
   color: var(--color-grey);
 }
 
 .game__cell.game__cell--userfilled {
   color: var(--color-grey-light);
 }
 
 .game__cell.game__cell--selected {
   color: var(--color-orange);
   background-color: var(--color-orange);
 }
 
 .game__cell.game__cell--filled.game__cell--selected {
   color: var(--color-grey);
   background-color: var(--color-orange);
 }
 
 .game__cell.game__cell--userfilled.game__cell--selected {
   color: var(--color-grey-light);
   background-color: var(--color-orange);
 }
 
 .game__cell.game__cell--highlightselected {
   color: var(--color-orange-dark);
   background-color: var(--color-orange-dark);
 }
 
 .game__cell.game__cell--filled.game__cell--highlightselected {
   color: var(--color-grey);
   background-color: var(--color-orange-dark);
 }
 
 .game__cell.game__cell--userfilled.game__cell--highlightselected {
   color: var(--color-grey-light);
   background-color: var(--color-orange-dark);
 }
 
 .game__cell:nth-child(3n) {
   border-right: 2px solid var(--color-grey);
 }
 
 /**
  * Status Section
  */
 .status {
   width: 30%;
 }
 
 .status__difficulty {
   position: relative;
   top: 39px;
   left: 20px;
 }
 
 .status__difficulty-text {
   font-size: 16px;
   font-weight: 600;
   line-height: 2;
 }
 
 .status__difficulty-select {
   position: relative;
   display: inline-block;
   border: none;
   -moz-appearance: none;
   -webkit-appearance: none;
   appearance: none;
   padding-left: 10px;
   padding-right: 30px;
   font-size: 14px;
   font-weight: 600;
   line-height: 2;
   color: var(--color-grey-light);
   background-color: var(--background-color);
   background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
   background-repeat: no-repeat;
   background-position: right .7em top 50%, 0 0;
   background-size: .65em auto, 100%;
 }
 
 .status__time {
   position: relative;
   top: 70px;
   left: 10px;
   text-align: center;
   font-family: 'Teko', sans-serif;
   font-size: 30px;
   line-height: 1.5;
   letter-spacing: 0.2em;
   background-color: var(--color-orange);
   color: var(--color-grey-light);
   padding-top: 4px;
   margin: 0 30px;
 }
 
 .status__numbers {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   position: relative;
   top: 93px;
   left: 10px;
 }
 
 .status__number {
   text-align: center;
   font-family: 'Noto Sans', sans-serif;
   font-size: 26px;
   padding: 12px 0;
   cursor: pointer;
 }
 
 .status__number.status__number--selected {
   color: var(--color-blue);
 }
 
 .status__actions {
   position: relative;
   top: 100px;
   left: 10px;
   display: flex;
   flex-wrap: wrap;
 }
 
 .status__action-undo,
 .status__action-erase,
 .status__action-hint,
 .status__action-mistakes-mode,
 .status__action-fast-mode {
   text-align: center;
   position: relative;
 }
 
 .status__action-undo,
 .status__action-erase,
 .status__action-hint {
   width: 33.33%;
   cursor: pointer;
 }
 
 .status__action-mistakes-mode {
   width: 60%;
 }
 
 .status__action-fast-mode {
   width: 40%;
 }
 
 .status__action-svg,
 .status__action-mistakes-mode-switch,
 .status__action-fast-mode-switch {
   position: absolute;
   left: 50%;
   transform: translate(-50%, 0);
 }
 
 .status__action-undo .status__action-svg {
   top: 18px;
   width: 38%;
   height: 38%;
 }
 
 .status__action-erase .status__action-svg {
   top: 20px;
   width: 32%;
   height: 32%;
 }
 
 .status__action-hint .status__action-svg {
   top: 11px;
   width: 48%;
   height: 48%;
 }
 
 .status__action-mistakes-mode-switch,
 .status__action-fast-mode-switch {
   top: 16px;
   display: inline-block;
   width: 46px;
   height: 26px;
 }
 
 .status__action-text {
   font-size: 16px;
   font-weight: 600;
   line-height: 1.2;
   padding-top: 50px;
   color: black;
 }
 
 /* Hide default HTML checkbox */
 .status__action-mistakes-mode-switch input,
 .status__action-fast-mode-switch input {
   opacity: 0;
   width: 0;
   height: 0;
 }
 
 /* The slider */
 .status__action-mistakes-mode-slider,
 .status__action-fast-mode-slider {
   position: absolute;
   cursor: pointer;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: var(--color-grey-lighter);
   border-radius: 26px;
   transition: .4s;
 }
 
 .status__action-mistakes-mode-slider:before,
 .status__action-fast-mode-slider:before {
   position: absolute;
   content: "";
   height: 20px;
   width: 20px;
   left: 3px;
   bottom: 3px;
   background-color: var(--background-color);
   border-radius: 50%;
   transition: .4s;
 }
 
 input:checked + .status__action-mistakes-mode-slider,
 input:checked + .status__action-fast-mode-slider {
   background-color: var(--color-blue);
 }
 
 input:focus + .status__action-mistakes-mode-slider,
 input:focus + .status__action-fast-mode-slider {
   box-shadow: 0 0 1px var(--color-blue);
 }
 
 input:checked + .status__action-mistakes-mode-slider:before,
 input:checked + .status__action-fast-mode-slider:before {
   transform: translateX(20px);
 }
 
 /**
  * Footer Section
  */
 .footer {
   color: var(--color-grey);
   font-weight: 400;
   margin: 80px;
   text-align: center;
   font-size: 14px;
 }
 
 .footer a {
   color: var(--color-blue);
   text-decoration: none;
   padding-bottom: 0px;
   border-bottom: 2px dotted var(--color-blue);
 }
 
 .footer a:visited {
   color: var(--color-blue);
 }
 
 /**
  * Overlay Section
  */
 .overlay {
   position: fixed;
   display: none;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: rgba(0,0,0,0.5);
   z-index: 2;
   cursor: pointer;
 }
 
 .overlay--visible {
   display: block;
 }
 
 .overlay__text{
   position: absolute;
   width: 100%;
   text-align: center;
   top: 50%;
   left: 50%;
   font-family: 'Source Sans Pro', sans-serif;
   font-size: 60px;
   font-weight: 600;
   color: var(--color-orange);
   text-shadow: 0 2px 4px rgba(0, 0, 0, 0.20),
               0 10px 20px rgba(0, 0, 0, 0.25);
   transform: translate(-50%,-50%);
   -ms-transform: translate(-50%,-50%);
 }
 
 .overlay__textspan1 {
   color: var(--color-grey-lighter);
 }
 
 .overlay__textspan2 {
   color: var(--color-blue);
 }
 
 /**
  * Add this to blur any DIV!
  */
 .blur {
   -webkit-filter: blur(2px);
   -moz-filter: blur(2px);
   -o-filter: blur(2px);
   -ms-filter: blur(2px);
   filter: blur(2px);
 }
 
 /**
  * Responsive Web Design!
  * 5 Modes:
  * 1. screen width > 670 px (2 column mode)
  * 2. 500px < screen width <= 670 px (1 column mode from here down...)
  * 3. 350px < screen width <= 500 px
  * 4. 300px < screen width <= 350 px
  * 5. screen width <= 300 px
  */
  @media screen and (max-width: 1024px) { /** Original CSS */
    .sudoku-container {
      max-width: 100vw;
    }
  }

 @media screen and (max-width: 770px) {
   .innercontainer {
     max-width: 500px;
     margin: 0 auto;
   }
   .game,
   .status {
     width: 100%;
     display: flex;
     flex-wrap: wrap;
   }
   .game__board {
     width: 100%;
   }
   .game__cell {
     text-align: center;
   }
   .status__difficulty {
     top: -18px;
     left: 0;
     width: 70%;
   }
   .status__time {
     top: -20px;
     left: 0;
     width: 30%;
     margin: 0;
     background-color: var(--background-color);
     text-align: right;
     font-size: 22px;
   }
   .status__numbers {
     top: -10px;
     left: 0;
     width: 100%;
     grid-template-columns: repeat(9, 1fr);
     background-color: var(--color-orange);
   }
   .status__number {
     padding: 12px 0;
   }
   .status__actions {
     top: 0;
     left: 0;
     width: 100%;
   }
   .status__action-undo,
   .status__action-erase,
   .status__action-hint {
     width: 16.66%;
   }
 
   .status__action-mistakes-mode {
     width: 30%;
   }
 
   .status__action-fast-mode {
     width: 20%;
   }
 }
 
 @media screen and (max-width: 500px) {
   .game__cell,
   .status__number {
     font-size: 18px;
     padding: 8px 0;
   }
   .status__action-text {
     font-size: 14px;
   }
 
   .overlay__text {
     font-size: 50px;
   }
 }
 
 @media screen and (max-width: 370px) {
   .game__cell,
   .status__number {
     font-size: 16px;
     padding: 4px 0;
   }
   .status__action-undo,
   .status__action-erase,
   .status__action-hint {
     width: 33%;
   }
 
   .status__action-mistakes-mode {
     width: 60%;
   }
 
   .status__action-fast-mode {
     width: 40%;
   }
 
   .overlay__text {
     font-size: 40px;
   }
 }
 
 @media screen and (max-width: 300px) {
   .game__cell,
   .status__number {
     font-size: 14px;
     padding: 2px 0;
   }
   .status__difficulty-text {
     display: none;
   }
   .status__difficulty-select {
     padding-left: 0;
     margin-top: 3px;
   }
   .overlay__text {
     font-size: 30px;
   }
 }
 
.pod-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 0;
}

.pod-container img {
  width: 80%;
  border-radius: 20px;
}

.pod-iframe {
  max-height: 102px;
  max-width: 400px;
}

@media screen and (max-width: 760px) {
  .pod-container img {
    width: 100%;
    border-radius: 0px;
  }
  .pod-iframe {
    width: 70vw;
  }
}
.snowflake {
  color: #fff;
  font-size: 1.5em;
  font-weight: bolder;
  font-family: Arial, sans-serif;
  text-shadow: 0 0 5px #000;
  position: fixed;
  top: -10%;
  z-index: 9999;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  -webkit-animation-duration: 10s, 3s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;
}
.snowflake:nth-of-type(0) {
  left: 1%;
  animation-delay: 0s, 0s;
}
.snowflake:nth-of-type(1) {
  left: 10%;
  animation-delay: 1s, 1s;
}
.snowflake:nth-of-type(2) {
  left: 20%;
  animation-delay: 6s, 0.5s;
}
.snowflake:nth-of-type(3) {
  left: 30%;
  animation-delay: 4s, 2s;
}
.snowflake:nth-of-type(4) {
  left: 40%;
  animation-delay: 2s, 2s;
}
.snowflake:nth-of-type(5) {
  left: 50%;
  animation-delay: 8s, 3s;
}
.snowflake:nth-of-type(6) {
  left: 60%;
  animation-delay: 6s, 2s;
}
.snowflake:nth-of-type(7) {
  left: 70%;
  animation-delay: 2.5s, 1s;
}
.snowflake:nth-of-type(8) {
  left: 80%;
  animation-delay: 1s, 0s;
}
.snowflake:nth-of-type(9) {
  left: 90%;
  animation-delay: 3s, 1.5s;
}
.snowflake:nth-of-type(10) {
  left: 25%;
  animation-delay: 2s, 0s;
}
.snowflake:nth-of-type(11) {
  left: 65%;
  animation-delay: 4s, 2.5s;
}
.snowflake:nth-of-type(12) {
  left: 5%;
  animation-delay: 2s, 2s;
}
.snowflake:nth-of-type(13) {
  left: 15%;
  animation-delay: 3s, 3s;
}
.snowflake:nth-of-type(14) {
  left: 25%;
  animation-delay: 9s, 9s;
}
.snowflake:nth-of-type(15) {
  left: 35%;
  animation-delay: 0s, 0.5s;
}
.snowflake:nth-of-type(16) {
  left: 45%;
  animation-delay: 8s, 8s;
}
.snowflake:nth-of-type(17) {
  left: 55%;
  animation-delay: 6s, 6s;
}
.snowflake:nth-of-type(18) {
  left: 75%;
  animation-delay: 4s, 4s;
}
.snowflake:nth-of-type(19) {
  left: 85%;
  animation-delay: 2s, 2s;
}
.snowflake:nth-of-type(20) {
  left: 95%;
  animation-delay: 0s, 0s;
}
@keyframes snowflakes-fall {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
}
@keyframes snowflakes-shake {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(80px);
  }
}

.pete-snowflake {
  height: 50px;
}

p {
  font-size: 12px;
  text-decoration: none;
  color: #ffffff;
}

.login {
  display: flex;
  justify-content: center;
}

.box {
  text-align: center;
  border: 1px solid White;
  background-size: 56.57px 56.57px;
  z-index: 1;
  width: 300px;
  padding: 20px;
  position: absolute;
  margin: 20px auto;
  top: 30%;
  left: 41%;
  background-color: rgba(60, 60, 59, 0.75);
}

.box25 {
  text-align: center;
  border: 1px solid White;
  background-size: 56.57px 56.57px;
  z-index: 1;
  width: 300px;
  padding: 20px;
  position: absolute;
  margin: 20px auto;
  top: 30%;
  left: 41%;
  background-color: rgba(60, 60, 59, 0.75);
}

.box img {
  width: 100%;
}

.password {
  border-radius: 4px;
  background: #ecf0f1;
  border: #ffffff 0px solid;
  text-align: center;
  padding: 8px;
  width: 90%;
  font-size: 1.5em;
  display: block;
  margin: 0 auto;
  opacity: 0.4;
}

.password-text {
  color: white;
}

.checkbox-container {
  margin-top: 3%;
  color: white;
}

.show-hide-checkbox {
  margin-right: 3%;
}

.login-btn {
  background: white;
  width: 90%;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #1e7a8e;
  border-radius: 4px;
  border: #ffffff 0px solid;
  margin-top: 20px;
  margin-bottom: 20px;
  float: left;
  margin-left: 16px;
  font-weight: 900;
  font-size: 1.5em;
  text-align: center;

}

.login-btn:hover {
  background: #A6A6A6;
}

@media screen and (max-width: 1024px) {
  .box {
    top: 30%;
    left: 35%;
  }
}
@media screen and (max-width: 768px) {
  .box {
    top: 30%;
    left: 31%;
  }
}
@media screen and (max-width: 414px) {
  .box {
    top: 20%;
    left: 15%;
  }
}
@media screen and (max-width: 375px) {
  .box {
    top: 20%;
    left: 10%;
  }
}
@media screen and (max-width: 320px) {
  .box {
    top: 20%;
    left: 3%;
  }
  .box h3,
  .box input {
    font-size: 1rem;
  }
}

.popup {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-content: center;
  left: 20%;
  right: 20%;
  top: 10%;
  bottom: 20%;
  margin: auto;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.75);
  text-align: center;
  padding: 20px;
  height: 20vh;
  width: 50vw;
}

.popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .popup {
    height: 30vh;
    width: 30vh;
  }
}
@media screen and (max-width: 500px) {
  .popup {
    height: 20vh;

  }
  .popup h1 {
    font-size: 1rem;
  }
}
@media screen and (max-width: 320px) {
  .popup {
    height: 25vh;
  }
}

