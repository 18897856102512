.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  padding-top: 10px;
}

body {
  background-image: url("./images/Moon_Santa_STA.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.main-page {
  height: 80vh;
  overflow-y: auto; /* Added to help header stay fixed */
  -ms-overflow-style: none;  /* IE and Edge */ /* Added to help header stay fixed */
  scrollbar-width: none;  /* Firefox */ /* Added to help header stay fixed */
}

.main-page::-webkit-scrollbar { /* Added to help header stay fixed */
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.component-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 80vw;
  height: auto;
}
.component-footer-col {
  height: 20vh;
}

.App-main {
  filter: blur(10px);
  pointer-events: none;
}

.hiddenPete {
  visibility: hidden;
  position: absolute;
  height: 255px;
  z-index: -1;
  bottom: 0px;
  right: 0px;
}

.calendar-windows-container {
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  height: 90vh;
}
.calendar-windows-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.calendar-window {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20vh;
  width: 15vh;
  padding: 0;
  color: #ffffff;
}

.calendar-window img {
  height: 15vh;
  width: 15vh;
  padding-bottom: 10px;
}

.calendar-window b {
  font-size: 2rem;
  color: white;
  height: 3rem;
  width: 3rem;
  background-color: rgba(60, 60, 59, 0.75);
  border-radius: 50%;
}

.activity-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 30px;
}

.activity-row {
  background-color: rgba(60, 60, 59, 0.75);
  padding: 10px 0;
  margin: 0;
}

.activity-row {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;;
}

Button.btn.btn-primary {
  margin: 10px auto;
  background-color: rgba(60, 60, 59, 0.75);
  border-color: white;
}

.wall-of-awesome-image {
  height: 55vh;
  width: 100%;
}

.challenge-text {
  padding-top: 20px;
  background-color: #f2f2f2;
  margin: 0;
  color: #3C3C3B;
}

.challenge-text p {
  padding-top: 20px;
  color: #3C3C3B;
}

.challenge-img {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1024px) {
  .calendar-window {
    height: 17vh;
    width: 12vh;
    margin: 0;
  }
  .calendar-window img {
    height: 10vh;
    width: 10vh;
  }
}

@media screen and (max-width: 770px) {
  .calendar-window {
    height: 14vh;
    width: 11vh;
  }
  .calendar-window img {
    height: 11vh;
    width: 11vh;
  }
  .App header h1 {
    font-size: 1.5rem;
  }
  .App header h2 {
    font-size: 1.25rem;
  }
  .awesome,
  .activity-row {
    border-radius: 0px;
  }
  .component-header-col {
    margin-bottom: 0;
  }
  .component-footer-col {
    margin-top: 0;
  }
}

@media screen and (max-width: 500px) {
  .activity-page-container h3,
  .activity-page-container h4 {
    font-size: 0.75rem;
  }
  .calendar-window b {
    font-size: 0.5rem !important;
    line-height: 1.5rem !important;
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  Button.btn.btn-primary {
    font-size: 0.75rem !important;
    font-weight: bold;
  }
  .calendar-windows-container {
    margin-top: 5vh;
  }
  .activity-row {
    height: auto;
    overflow-y: scroll;
  }
  .component-col {
    height: auto;
  }
}
@media screen and (max-width: 375px) {
  Button.btn.btn-primary {
    font-weight: bold;
    padding: 2.5px;
    display: block;
    font-size: 0.5rem !important;
  }
  .calendar-window {
    height: 15vh;
  }
  .calendar-window b {
    font-size: 0.5rem !important;
    line-height: 1.25rem !important;
    height: 1.25rem !important;
    width: 1.25rem !important;
  }
}
