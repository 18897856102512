.popup {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-content: center;
  left: 20%;
  right: 20%;
  top: 10%;
  bottom: 20%;
  margin: auto;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.75);
  text-align: center;
  padding: 20px;
  height: 20vh;
  width: 50vw;
}

.popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .popup {
    height: 30vh;
    width: 30vh;
  }
}
@media screen and (max-width: 500px) {
  .popup {
    height: 20vh;

  }
  .popup h1 {
    font-size: 1rem;
  }
}
@media screen and (max-width: 320px) {
  .popup {
    height: 25vh;
  }
}
