.loading-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
}

@keyframes loadingTextFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.video-player {
  margin: 20px auto;
  opacity: 0;
  width: 100% !important;
}

.video-instructions {
  text-align: left;
  width: 100%;
  padding: 40px;
  border: 1px solid White;
  margin: 10px 0 20px;
}

@keyframes videoFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 685px) {
  .video-instructions {
    font-size: 0.75rem;
    width: 100%;
    padding: 5px;
  }
}
