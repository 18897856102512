p {
  font-size: 12px;
  text-decoration: none;
  color: #ffffff;
}

.login {
  display: flex;
  justify-content: center;
}

.box {
  text-align: center;
  border: 1px solid White;
  background-size: 56.57px 56.57px;
  z-index: 1;
  width: 300px;
  padding: 20px;
  position: absolute;
  margin: 20px auto;
  top: 30%;
  left: 41%;
  background-color: rgba(60, 60, 59, 0.75);
}

.box25 {
  text-align: center;
  border: 1px solid White;
  background-size: 56.57px 56.57px;
  z-index: 1;
  width: 300px;
  padding: 20px;
  position: absolute;
  margin: 20px auto;
  top: 30%;
  left: 41%;
  background-color: rgba(60, 60, 59, 0.75);
}

.box img {
  width: 100%;
}

.password {
  border-radius: 4px;
  background: #ecf0f1;
  border: #ffffff 0px solid;
  text-align: center;
  padding: 8px;
  width: 90%;
  font-size: 1.5em;
  display: block;
  margin: 0 auto;
  opacity: 0.4;
}

.password-text {
  color: white;
}

.checkbox-container {
  margin-top: 3%;
  color: white;
}

.show-hide-checkbox {
  margin-right: 3%;
}

.login-btn {
  background: white;
  width: 90%;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #1e7a8e;
  border-radius: 4px;
  border: #ffffff 0px solid;
  margin-top: 20px;
  margin-bottom: 20px;
  float: left;
  margin-left: 16px;
  font-weight: 900;
  font-size: 1.5em;
  text-align: center;

}

.login-btn:hover {
  background: #A6A6A6;
}

@media screen and (max-width: 1024px) {
  .box {
    top: 30%;
    left: 35%;
  }
}
@media screen and (max-width: 768px) {
  .box {
    top: 30%;
    left: 31%;
  }
}
@media screen and (max-width: 414px) {
  .box {
    top: 20%;
    left: 15%;
  }
}
@media screen and (max-width: 375px) {
  .box {
    top: 20%;
    left: 10%;
  }
}
@media screen and (max-width: 320px) {
  .box {
    top: 20%;
    left: 3%;
  }
  .box h3,
  .box input {
    font-size: 1rem;
  }
}
